<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'
import { usePurchases } from '/~/composables/purchases'

export default {
  name: 'profile-epurchase-balance-tracker-modal',
  components: {
    DrawerIconHeader,
    DrawerModal,
    BaseButton,
    BaseCurrencyInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
  },
  setup() {
    const { selectedPurchaseCard, updateBalance } = usePurchases()

    return {
      selectedPurchaseCard,
      updateBalance,
    }
  },
  data() {
    return {
      inputValue: '',
      min: 0,
      max: 0,
      submitting: false,
      backendErrors: {},
    }
  },
  computed: {
    disabled() {
      return (
        this.submitting ||
        this.inputValue > this.max ||
        this.inputValue < this.min
      )
    },
    inputError() {
      return (
        this.errors?.first('inputValue') || this.backendErrors.card_balance?.[0]
      )
    },
  },
  watch: {
    selectedPurchaseCard() {
      this.setFormValues()
    },
  },
  created() {
    this.setFormValues()
  },
  methods: {
    setFormValues() {
      if (this.selectedPurchaseCard) {
        this.inputValue = this.selectedPurchaseCard.balance
        this.max = this.selectedPurchaseCard.value
      }
    },
    async handleUpdateClick() {
      this.submitting = true
      this.backendErrors = {}

      try {
        await this.updateBalance({
          uuid: this.selectedPurchaseCard.uuid,
          balance: this.inputValue || 0,
        })

        this.hide()
      } catch (error) {
        this.backendErrors = error
      } finally {
        this.submitting = false
      }
    },
    onKeyup(keyEvent) {
      const enter = keyEvent.keyCode === 13 || keyEvent.which === 13

      if (!this.disabled && enter) {
        this.handleUpdateClick()
      }
    },
    hide() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<template>
  <drawer-modal :to="to" :visible="visible" @hide="hide">
    <template #iconHeader>
      <drawer-icon-header
        title="Balance Tracker"
        icon="secure"
        :action="hide"
      />
    </template>
    <div class="px-5 pb-[30px]">
      <div class="relative mx-auto w-48 max-w-full">
        <div class="flex h-[45px] items-center justify-center rounded border">
          <div
            class="flex h-full items-center rounded-l border-r bg-divider-light px-2.5 text-center text-sm text-neutral-400"
          >
            $
          </div>
          <base-currency-input
            v-model="inputValue"
            class="w-auto px-2.5 text-xl font-bold text-primary"
            :currency="null"
            :value-range="{ min, max }"
            @keyup="onKeyup"
          />
        </div>
        <div v-if="inputError" class="text-center text-sm text-fg-error">
          {{ inputError }}
        </div>
      </div>
      <div class="mt-[30px] px-[5px] pb-10 text-center">
        Please update your remaining balance manually. For a live balance simply
        ask the cashier, refer to the retailer's website, or contact their
        customer service team.
      </div>

      <base-button
        :disabled="disabled"
        :loading="submitting"
        full-width
        @click="handleUpdateClick"
      >
        Update
      </base-button>
    </div>
  </drawer-modal>
</template>
